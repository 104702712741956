<template>
  <div class="store">
    <div class="store-btnBox">
      <button class="btnBox-item start" @click="jump">开始存证</button>
      <button class="btnBox-item" @click="uploadPop(1, '选择Word文档')">
        选择Word文档
      </button>
      <button class="btnBox-item" @click="uploadPop(2, '选择图片')">
        选择图片
      </button>
      <button class="btnBox-item" @click="uploadPop(3, '选择视频')">
        选择视频
      </button>
      <button class="btnBox-item" @click="uploadPop(4, '选择音频')">
        选择音频
      </button>
      <button class="btnBox-item" @click="uploadPop(5, '选择文件')">
        选择文件
      </button>
      <button class="btnBox-item" @click="handleWebStore">网页存证</button>
    </div>
    <router-view></router-view>
    <!--确认信息弹窗-->
    <el-dialog
      :visible.sync="comfirmInfoPopup"
      :modal="false"
      :close-on-click-modal="false"
      title="存证信息核对"
      class="comfirm-info"
    >
      <div class="content">
        <div class="input-wrap">
          <div class="title">存证人</div>
          <div class="input-inner">
            <el-input v-model="storeUser" :disabled="true" placeholder="小明" />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title">存证人身份证号</div>
          <div class="input-inner">
            <el-input
              v-model="storeUserId"
              :disabled="true"
              placeholder="441802200002230854"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title">存证账号</div>
          <div class="input-inner">
            <el-input
              v-model="storeAccount"
              :disabled="true"
              placeholder="156154"
            />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title">存证文件名</div>
          <div class="input-inner">
            <el-input v-model="storeFileName" placeholder="请输入文件名称" />
          </div>
        </div>
        <div class="input-wrap">
          <div class="title">存证数据大小</div>
          <div class="input-inner">
            <el-input
              v-model="storeDataSize"
              :disabled="true"
              placeholder="5M"
            />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn sureInfo" @click="sureInfo">确定</div>
          <div class="footer-btn cancel" @click="comfirmInfoPopup = false">
            取消
          </div>
        </div>
      </div>
    </el-dialog>
    <!--支付提示-->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="payTipsPopup"
      class="pay-tips"
    >
      <div class="content">
        完成本次存证，需要支付人民币{{ orderData.orderPrice }}元。
        官方存证证据包存储期三年，存证数据包 存放于“
        <span>智能网盘/系统文件/原创存证</span>”文件夹。
      </div>
      <div class="footer">
        <div class="footer-btn" @click="toPay">支付</div>
        <div class="footer-btn" @click="payTipsPopup = false">取消</div>
      </div>
    </el-dialog>
    <!-- 扫码支付 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="扫码支付"
      :visible.sync="paymentPopup"
      class="payment"
    >
      <div class="content">
        <div class="notes">请使用微信扫码下方二维码完成支付</div>
        <div class="imgBox">
          <img :src="qrCodeUrl" alt="" />
        </div>
        <div class="price">
          应付款：<span
            >￥<span>{{ orderData.orderPrice }}</span></span
          >
        </div>
      </div>
    </el-dialog>
    <!--存证完成弹框提示-->
    <transition name="store-complete-trans">
      <div class="store-complete-content" v-if="dialogStoreComplete">
        <i class="icon"></i>
        <div class="desc">
          存证已完成，溯源报告存放于个人“<span class="blue"
            >智能网盘/系统文件/原创存证</span
          >
          ”，官方存储系统保存该存证数据包三年
        </div>
      </div>
    </transition>
    <!--网页存证-->
    <el-dialog
      :modal="true"
      :close-on-click-modal="false"
      title="请输入网址"
      :visible.sync="webStorePopup"
      class="web-store"
    >
      <div class="content">
        <div class="input-wrap">
          <div class="title"><!--新增--></div>
          <div class="input-inner">
            <el-input v-model="webUrl" placeholder="网址输入栏" />
          </div>
        </div>
        <!-- <div class="options">
          <span clas="batch-import">+&nbsp;批量导入</span>
          <span class="down-report">+&nbsp;下载批量导入表</span>
        </div> -->
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn sure-weburl" @click="sureWebStore">确定</div>
          <div class="footer-btn cancel" @click="webStorePopup = false">
            取消
          </div>
        </div>
      </div>
    </el-dialog>
    <!--文件上传弹出框 -->
    <el-dialog
      v-dialogDrag
      :title="uploadTitle"
      :visible.sync="uploadVisible"
      width="45%"
      class="upload"
    >
      <div class="content-text">
        原创存证是内容、文档、照片、视频、文件等的电子数据指纹，辅以国家授时中心时间戳认证，是反抄袭与原创保护的关键性证据。
      </div>
      <!--上传器-->
      <UploadBigFile
        ref="uploader"
        class="uploadSlot"
        :parentId="parentId"
        :columnType="columnType"
        :fileType="fileType"
        @onFileSuccess="onFileSuccess"
        @onFileError="onFileError"
      >
      </UploadBigFile>
    </el-dialog>
    <!--免费存证-->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="freeSaveCard"
      class="freeSaveCard"
    >
      <div class="content">
        {{ SaveCardNum.text }}
      </div>
      <div class="footer">
        <div class="footer-btn" @click="useFreeSaveCard">
          {{ SaveCardNum.type == 1 ? "使用" : "支付" }}
        </div>
        <div
          class="footer-btn"
          @click="
            () => {
              payTipsPopup = true;
              freeSaveCard = false;
            }
          "
        >
          取消
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadBigFile from "components/disk/StoreRightBoxUpload";
import {
  addStr,
  addWeb,
  addOrder,
  orderPay,
  queryWechatQrCode,
  zipPackDownload,
  trustedCTSRResult,
  trustedWebResult,
  delEvidenceParseQueue,
  findFreeExistEvidence,
  deductionsFreeExistEvidence,
} from "api/store";
import { isEmptyObj } from "utils/utils";
export default {
  data() {
    return {
      //显示文件上传弹框
      uploadVisible: false,
      //上传弹框标题
      uploadTitle: "",
      //文件类型
      fileType: 1,
      //上传类型 存证-3
      columnType: 3,
      //网盘parentId
      parentId: 0,
      fileList: [],
      //确认信息
      comfirmInfoPopup: false,
      //支付提示
      payTipsPopup: false,
      //扫码支付弹窗
      paymentPopup: false,
      //存证完成提示
      dialogStoreComplete: false,
      //网页存证弹窗
      webStorePopup: false,
      //存证人
      storeUser: "",
      //存证人身份证号
      storeUserId: "",
      //存证账号
      storeAccount: "",
      //存证文件名
      storeFileName: "",
      //存证数据大小
      storeDataSize: "",
      //新增网址
      webUrl: "",
      //网页存证中
      in_webStore: false,
      //订单数据
      orderData: {},
      //微信扫码_计时器id
      qr_timer: null,
      //微信二维码url
      qrCodeUrl: "",
      // 免费存证次数
      SaveCardNum: "",
      // 免费存证弹窗
      freeSaveCard: false,
      // 文件数量
      ids: [],
      // 内容存证还是地址存证
      CompletedStoreIsWebResult: "",
    };
  },
  methods: {
    //确认信息
    sureInfo() {
      this.payTipsPopup = true;
    },
    //去支付
    toPay() {
      this.payTipsPopup = false;
      this.tuneUpPay();
    },
    //点击选择文件按钮
    uploadPop(type, title) {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          if (!isEmptyObj(this.orderData)) {
            return this.cancelPayfor();
          }
          this.uploadVisible = true;
          this.uploadTitle = title;
          this.fileType = type;
          //清空当前批量队列
          delEvidenceParseQueue();
        },
      });
    },
    //文件上传成功
    onFileSuccess(file) {
      if (!file.isFolder) {
        this.fileList.push(1);
        if (this.fileList.length == file.uploader.files.length) {
          this.uploadVisible = false;
          this.$router.push({
            path: "/store/storeTraceList",
          });
          setTimeout(() => {
            this.$bus.$emit("firstFindQueue");
          }, 1000);
        }
      }
    },
    //文件上传失败
    onFileError(file) {
      if (!file.isFolder) {
        this.fileList.push(0);
        if (this.fileList.length == file.uploader.files.length) {
          this.uploadVisible = false;
          this.$router.push({
            path: "/store/storeTraceList",
          });
          setTimeout(() => {
            this.$bus.$emit("firstFindQueue");
          }, 1000);
        }
      }
      this.fileList = [];
      this.$bus.$emit("clearFileList");
    },
    //点击开始存证
    jump() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          //内容存证
          if (this.$route.name == "StoreRightBox") {
            this.$bus.$emit("contentStore");
          }
          //批量文件存证
          if (this.$route.name == "StoreTraceList") {
            if (!isEmptyObj(this.orderData)) {
              this.cancelPayfor();
            } else {
              this.$bus.$emit("firstFindQueue");
            }
          }
        },
      });
    },
    //添加订单
    async addOtherOrder(fileIds) {
      const params = {
        platform: 1,
        producType: 2,
        pids: fileIds,
      };
      const res = await addOrder(params);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "添加订单成功",
        });
        this.orderData = res.data;

        this.getSaveCardNum();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    //查询用户免费存证次数
    async getSaveCardNum(i) {
      let form = new FormData();
      form.append("existNum", this.ids.length != 0 ? this.ids.length : 1);
      this.CompletedStoreIsWebResult = i;
      const res = await findFreeExistEvidence(form);
      if (res.code !== 200) {
        this.$message({
          type: "success",
          message: "免费存证次数已用完!",
        });
        if (i == 1) {
          this.paymentPopup = true;
          this.queryQrCodeStatusByWord(1);
          return;
        } else if (i == 2) {
          this.paymentPopup = true;
          this.queryQrCodeStatusByWord(2);
          return;
        } else {
          if (!isEmptyObj(this.orderData)) {
            return (this.payTipsPopup = true);
          }
        }
      }
      this.freeSaveCard = true;
      this.SaveCardNum = res.data;
    },

    // 扣减用户免费存证次数
    async useFreeSaveCard() {
      let form = new FormData();
      form.append("existNum", this.ids.length != 0 ? this.ids.length : 1);
      form.append(
        "oid",
        this.orderData.otherOrderId
          ? this.orderData.otherOrderId
          : this.orderData.orderId
      );

      const res = await deductionsFreeExistEvidence(form);

      if (res.code == 200) {
        if (this.SaveCardNum.type == 2) {
          return this.toPay();
        } else {
          this.freeSaveCard = false;
          //提交触发清空文件列表事件
          if (this.CompletedStoreIsWebResult == 1) {
            this.completedStore();
          } else if (this.CompletedStoreIsWebResult == 2) {
            this.WebResult();
          } else {
            this.$bus.$emit("clearFileList");
            this.$bus.$emit("startFindQueue");
          }

          this.orderData = {};
        }
      }

      // console.log(res);
    },

    //调起支付
    async tuneUpPay() {
      const params = {
        oid: this.orderData.otherOrderId,
      };
      const res = await orderPay(params);
      if (res.code == 200) {
        this.orderData = res.data;
        this.qrCodeUrl = res.data ? res.data.codeImg : "";
        this.paymentPopup = true;
        this.queryQrCodeStatusByQueue();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //查询批量存证微信扫码状态
    async queryQrCodeStatusByQueue() {
      this.qr_timer = setInterval(() => {
        const params = {
          oid: this.orderData.otherOrderId
            ? this.orderData.otherOrderId
            : this.orderData.orderId,
        };
        queryWechatQrCode(params).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "支付成功",
            });
            this.freeSaveCard = false;
            this.paymentPopup = false;
            this.orderData = {};
            this.qrCodeUrl = "";
            this.fileList = [];
            //提交触发清空文件列表事件
            this.$bus.$emit("clearFileList");
            this.$bus.$emit("startFindQueue");
            clearInterval(this.qr_timer);
          }
        });
      }, 1500);
    },
    //查询文字存证微信扫码状态
    async queryQrCodeStatusByWord(i) {
      this.qr_timer = setInterval(() => {
        const params = {
          oid: this.orderData.orderId,
        };
        queryWechatQrCode(params).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "支付成功",
            });
            this.paymentPopup = false;
            this.qrCodeUrl = "";
            i == 1 ? this.completedStore() : this.WebResult();
            clearInterval(this.qr_timer);
          }
        });
      }, 1500);
    },
    //打包zip下载
    async downloadPackStoreZip(id, fileName) {
      this.$store.commit("playLifeLoading", true);
      let form = new FormData();
      form.append("existsId", id);
      const res = await zipPackDownload(form);
      if (res) {
        const blob = new Blob([res], {
          type: "application/zip",
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = objectUrl;
        link.setAttribute("download", fileName + ".zip");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
      this.$store.commit("playLifeLoading", false);
    },
    //内容存证-添加订单调起支付
    async addContentStore(params) {
      this.$store.commit("playLifeLoading", true);
      let data = {
        content: params.content,
        fileName: params.fileName + ".docx",
      };
      // let form = new FormData();
      // form.append("content", params.content);
      // form.append("fileName",params.fileName);
      const res = await addStr(data);
      this.$store.commit("playLifeLoading", false);
      if (res.code == 200) {
        this.orderData = res.data;
        this.qrCodeUrl = this.orderData.codeImg;

        this.getSaveCardNum(1);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    async completedStore() {
      const params = {
        oid: this.orderData.orderId,
      };
      this.$bus.$emit("loading", true, "存证中", true);
      setTimeout(() => {
        this.$bus.$emit("loading", false);
        this.$router.push({
          path: "/store/storeRightList",
        });
      }, 2000);
      const res = await trustedCTSRResult(params);
      if (res.code == 200) {
        this.dialogStoreComplete = true;
        this.$message({
          type: "success",
          message: "存证成功",
        });
        setTimeout(() => {
          this.dialogStoreComplete = false;
        }, 2000);
        this.orderData = {};
      }
    },
    handleWebStore() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          this.webStorePopup = true;
        },
      });
    },
    async WebResult() {
      const params = {
        oid: this.orderData.orderId,
      };
      this.$bus.$emit("loading1", "存证中");
      setTimeout(() => {
        this.$bus.$emit("loading1", false);
        this.$router.push({
          path: "/store/storeRightList",
        });
      }, 2000);
      const res = await trustedWebResult(params);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "新增成功",
        });
        this.dialogStoreComplete = true;
        setTimeout(() => {
          this.dialogStoreComplete = false;
        }, 2000);
        this.orderData = {};
      }
    },
    //新增网页存证
    async addWebStore() {
      if (!this.webUrl) {
        return this.$message({
          type: "warning",
          message: "请先输入网址",
        });
      }
      let url =
        /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;

      if (!url.test(this.webUrl)) {
        return this.$message.error("请输入正确的网址格式");
      }
      let form = new FormData();
      form.append("url", this.webUrl);
      try {
        this.$bus.$emit("loading1", "网页存证提交中");
        const res = await addWeb(form);
        this.$bus.$emit("loading1", false);
        if (res.code == 200) {
          this.in_webStore = false;
          this.webStorePopup = false;
          this.webUrl = "";
          this.orderData = res.data;
          this.qrCodeUrl = this.orderData.codeImg;

          this.getSaveCardNum(2);
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } catch (e) {
        this.$message({
          type: "error",
          message: e.message,
          duration: 1200,
        });
        this.in_webStore = false;
        this.$bus.$emit("loading", false);
      }
    },
    //确定网页存证
    sureWebStore() {
      if (this.in_webStore) {
        return this.$message({
          type: "warning",
          message: "正在网址存证中",
          duration: 800,
        });
      }
      this.in_webStore = true;
      this.addWebStore();
    },
    //取消支付
    cancelPayfor() {
      this.$confirm("您当前有一笔未支付订单，是否继续支付", "提示", {
        confirmButtonText: "继续支付",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.qrCodeUrl
            ? (this.paymentPopup = true)
            : (this.payTipsPopup = true);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "取消支付",
          });
          this.orderData = {};
          this.qrCodeUrl = "";
          this.fileList = [];
          this.$router.push({
            path: "/store/storeRightBox",
          });
          this.$nextTick(() => {
            this.$bus.$emit("clearFileList");
          });
        });
    },
  },
  watch: {
    //关闭扫码弹窗时清除查询定时器
    paymentPopup(turn) {
      if (turn === false) {
        //作废当前内容存证订单数据
        if (this.$route.name == "StoreRightBox") {
          this.orderData = {};
          this.qrCodeUrl = "";
        }
        clearInterval(this.qr_timer);
      }
    },
  },
  components: {
    UploadBigFile,
  },
  created() {
    //监听提交存证内容参数事件
    this.$bus.$on("emitContentParams", (params) => {
      this.$message({
        type: "success",
        message: "开始存证",
      });
      this.addContentStore(params);
    });
    //监听提交批量文件ids事件
    this.$bus.$on("emitStoreFilesParams", (ids) => {
      this.$message({
        type: "success",
        message: "正在添加订单",
      });
      this.ids = ids;
      this.addOtherOrder(ids);
    });
    //监听下载压缩包事件
    this.$bus.$on("zipPackDownload", (id, filename) => {
      this.downloadPackStoreZip(id, filename);
    });
    //存证批量上传-返回内容存证
    this.$bus.$on("backContentStore", () => {
      if (!isEmptyObj(this.orderData)) {
        this.cancelPayfor();
      } else {
        this.fileList = [];
        this.$router.push({
          path: "/store/storeRightBox",
        });
        this.$nextTick(() => {
          this.$bus.$emit("clearFileList");
        });
      }
    });
  },
  destroyed() {
    this.$bus.$off("emitContentParams");
    this.$bus.$off("emitStoreFilesParams");
    this.$bus.$off("zipPackDownload");
    this.$bus.$off("backContentStore");
  },
};
</script>

<style lang="scss" scoped>
.store {
  height: 800px;
  border: 1px solid #eceff1;
  border-radius: 5px;
  display: flex;
  &-btnBox {
    width: 149px;
    border-right: 1px solid #eceff1;
    text-align: center;
    .btnBox-item {
      width: 100px;
      height: 37px;
      border: 1px solid $color-bg-heightlight;
      border-radius: 3px;
      font-size: $font-size-small;
      font-family: PingFang SC;
      font-weight: 400;
      color: $color-text-black;
      background: none;
      margin-top: 30px;
      cursor: pointer;
    }
    .start {
      color: $color-text-white;
      background: $color-bg-heightlight;
    }
  }
  // 上传弹窗
  .upload {
    ::v-deep .el-dialog {
      width: 45%;
      min-height: 340px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 15px;
        }
      }
      .el-dialog__body {
        padding: 27px 30px 0px;
        .content-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }
    }
  }
  //确认信息弹窗
  .comfirm-info {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 493px;
      height: 443px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 15px 15px 13px 10px;
        background-color: #f4f6f7;
        .el-dialog__title {
          font-size: 14px;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 18px;
          right: 11px;
        }
      }
      .el-dialog__body {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        flex: 1;
        padding: 20px 30px 30px;
        .content {
          flex: 1;
          .input-wrap {
            @include flex-between;
            margin-top: 20px;
            .title {
              width: 70px;
              white-space: pre-wrap;
              font-size: 14px;
              font-weight: 500;
              color: #333333;
            }
            .input-inner {
              flex: 0 0 350px;
              input {
                height: 40px;
                background: #ffffff;
                border: 1px solid #e3e3e3;
                color: #999999;
                cursor: default;
              }
              .is-disabled .el-input__inner {
                background: #f8f8f8;
                color: #333333;
              }
            }
            &:first-child {
              margin: 0;
            }
          }
        }
        .footer {
          @include flex-between(flex-end);
          .footer-btns {
            @include flex-start;
            margin-top: 30px;
            .footer-btn {
              @include noselect;
              width: 72px;
              height: 41px;
              border-radius: 3px;
              text-align: center;
              line-height: 41px;
              cursor: pointer;
              &.sureInfo {
                background: #4c8cff;
                border: 1px solid #4587ff;
                font-size: 16px;
                font-weight: 500;
                color: #ffffff;
              }
              &.cancel {
                margin-left: 12px;
                background: #ffffff;
                border: 1px solid #cecece;
                font-size: 16px;
                font-weight: 500;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
  //支付提示
  .pay-tips {
    ::v-deep .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 340px;
      height: 209px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 15px 39px;
        .content {
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
          line-height: 26px;
          span {
            color: $color-bg-heightlight;
          }
        }
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 25px;
          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }
  //扫码支付弹窗
  .payment {
    ::v-deep .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            width: 110px;
            height: 110px;
            margin-top: 30px;
            img {
              width: 110px;
              height: 110px;
            }
          }
          .price {
            margin-top: 30px;
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: #393939;
            span {
              color: #eb441e;
              span {
                font-size: $font-size-large;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
  //网页存证
  .web-store {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 428px;
      height: 274px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 15px 11px 13px 10px;
        background-color: #f4f6f7;
        .el-dialog__title {
          font-size: 14px;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 11px;
        }
      }
      .el-dialog__body {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        flex: 1;
        padding: 22px 30px 20px;
        .content {
          flex: 1;
          .input-wrap {
            @include flex-between;
            margin-top: 20px;
            .title {
              font-size: 14px;
              font-weight: 500;
              color: #333333;
            }
            .input-inner {
              flex: 0 0 308px;
              input {
                height: 50px;
                background: #ffffff;
                border: 1px solid #cecece;
                color: #999999;
              }
            }
          }
          .options {
            @include flex-between;
            margin-top: 14px;
            padding-left: 58px;
            span {
              font-size: 14px;
              font-weight: 500;
              color: #4587ff;
              cursor: pointer;
            }
          }
        }
        .footer {
          @include flex-between(flex-end);
          margin-top: 40px;
          .footer-btns {
            @include flex-start;
            .footer-btn {
              @include noselect;
              width: 72px;
              height: 41px;
              border-radius: 3px;
              text-align: center;
              line-height: 41px;
              cursor: pointer;
              &.sure-weburl {
                background: #4c8cff;
                border: 1px solid #4587ff;
                font-size: 16px;
                font-weight: 500;
                color: #ffffff;
              }
              &.cancel {
                margin-left: 12px;
                background: #ffffff;
                border: 1px solid #cecece;
                font-size: 16px;
                font-weight: 500;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
  //存证完成
  .store-complete-content {
    @include flex-between;
    align-items: normal;
    position: absolute;
    z-index: 2000;
    top: 8%;
    left: calc(50% - 175px);
    width: 351px;
    height: 63px;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #4587ff;
    border-radius: 10px;
    .icon {
      position: relative;
      width: 21px;
      height: 21px;
      flex-shrink: 0;
      margin-right: 15px;
      &::before {
        content: "";
        position: absolute;
        width: 21px;
        height: 21px;
        background: url("~assets/images/PromptSuccessful.png") no-repeat;
        background-size: 100%;
      }
    }
    .desc {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      .blue {
        color: #4587ff;
      }
    }
  }
  //免费存证
  .freeSaveCard {
    ::v-deep .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 340px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 12px 12px 0px;
        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 15px 39px;
        .content {
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
          line-height: 26px;
          span {
            color: $color-bg-heightlight;
          }
        }
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 25px;
          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
